<template>
  <v-container style="max-width:750px">
    <v-row justify="center">
      <v-tabs
          v-model="activeTab"
          grow
      >
        <v-tab key="loan" to="/tools/loan">Loan</v-tab>
        <v-tab key="amortize" to="/tools/amortize">Amortize</v-tab>
        <v-tab key="invest" to="/tools/invest">Invest</v-tab>
        <!-- TODO: Add an "advanced" tab...? -->
      </v-tabs>
      <router-view />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Tools",
  metaInfo: {
    title: 'Tools',
  },
  data: () => ({
    activeTab: 0,
  })
}
</script>

<style scoped>

</style>
